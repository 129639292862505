
import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/payments";

export async function getPayments() {
  const request = {
    keyword: "",
    pageNumber: 0,
    pageSize: 100,
    orderBy: ["id"],
  };
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const items = res.data.data;
      //TODO DEBT
      items.forEach((item: any) => {
        if (item.submissionDate)
          item.submissionDate = new Date(item.submissionDate);
        item.paymentLineItems.forEach((paymentLineItem: any) => {
            paymentLineItem.serviceDate = new Date(paymentLineItem.serviceDate);
        });
      });

      return items;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function searchPaymentV2(request) {
  return await ApiService.post(url + "/searchV2", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function searchPaymentExtV2(request) {
  return await ApiService.post(url + "/searchExtV2", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}


export async function searchPaymentsForRefund(request) {
  return await ApiService.post(url + "/SearchPaymentsForRefund", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function searchPayment(request) {
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function addPayment(payment) {
  return await ApiService.post(url, payment as never).catch(({ response }) => {
    processErrors(response);
  });
}

export async function getPayment(claimId) {
  return await ApiService.get(url + "/" + claimId)
    .then((res) => {
      const item = res.data;
      return item;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function reversePayment(request) {
  if (request) {
    return await ApiService.post(url + "/Reverse", request as never)
      .then((res) => {
        return res;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function updatePayment(payment) {
  if (payment) {
    return await ApiService.put(url + "/" + payment.id, payment as never)
      .then((res) => {
        return res;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function deletePayment(payment) {
  if (payment) {
    await ApiService.delete(url + "/" + payment?.id)
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

//AddPaymentRefundItemRequest
export async function addPaymentRefundItemRequest(request) {
  return await ApiService.post(url + "/addPaymentRefundItemRequest", request as never).catch(({ response }) => {
    processErrors(response);
  });
}

// export async function postPayment(claimActionRequest) {
//   return await ApiService.post(
//     url + "/postAction",
//     claimActionRequest as never
//   )
//     .then((res) => {
//       return res.data;
//     })
//     .catch(({ response }) => {
//       processErrors(response);
//     });
// }
