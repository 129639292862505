import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/paymentInstallment";

export async function getPaymentInstallment(id) {
  return await ApiService.get(url + "/" + id)
    .then((res) => {
      const paymentPlan = res.data;
      return paymentPlan;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}