import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export async function getPanel(includePatients = false) {
  return await ApiService.post("api/v1/encounterPanel", { includePatients: includePatients } as any)
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}
