
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
  watch,
  nextTick,
} from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { getPanel } from "@/api/encounter.api";
import { searchServicesV2 } from "@/api/services.api";
import { useStore } from "vuex";
import Multiselect from "@vueform/multiselect";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import { searchDictInsuranceCompanys } from "@/api/code-master-insurance-company.api";
import { PaymentService } from "@/modules/encounter/encounter.model";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import {
  PatientInsurance,
  selfPayer,
} from "../patientPayer/patientPayer.model";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface StatusCode {
  code: string;
  description: string;
}

export interface Data {
  items: PaymentService[];
  payment: any;
  patientId: any;
  keyword: string;
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  filter: {
    startDate: Date | string | null;
    endDate: Date | string | null;
    facility: [];
    currentResponsibleParty: [];
  };
  dict: {
    patientPayers: PatientInsurance[];
    adjustmentReasonCodes: StatusCode[];
    facilities: { id: string }[];
    actualPatientPayers: PatientInsurance[];
    actualFacilities: { id: string }[];
    paymentTypes: { code: string; description: string }[];
    transactionTypes: { code: string; description: string }[];
    insurancePaymentTypes: any[];
  };
  postingErrors: string[];
}
export default defineComponent({
  name: "ServiceHistoryPayment",
  props: ["patientId", "payment"],
  components: { Multiselect, DecimalInput, PaginationUi },
  setup(props, ctx) {
    const store = useStore();
    const orderList = [
      {
        name: "Encounter ID",
        key: "encounter.encounterId",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Service Date",
        key: "serviceDate",
        isFilter: true,
        keyword: null,
        formType: "isDate",
      },
      {
        name: "Facility",
        key: "encounter.facility.name",
        isFilter: true,
        keyword: null,
        formType: "facility",
      },
      {
        name: "Service",
        key: "service",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Description",
        isFilter: true,
        key: "description",
        keyword: null,
      },
      {
        name: "Total Charges",
        key: "totalCharges",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
        formType: "number",
      },
      {
        name: "Total Payments/Adjustments",
        key: "totalPaymentsAdjustments",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Balance Due",
        key: "balanceDue",
        isFilter: true,
        keyword: null,
        formType: "number",
      },
      {
        name: "Current Responsible Party",
        key: "encounter.responsibleParty",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
        formType: "patientPayer",
      },
      {
        name: "Payment Amount",
        key: "balanceDue",
        isFilter: false,
        keyword: null,
      },
    ];

    let organizationId = ref<string | null>("");

    watch(
      () => props.patientId,
      async (currentValue, oldValue) => {
        if (currentValue) {
          data.pagination.pageSize = 50;
          await getAll();
        } else {
          data.items = [];
          ctx.emit("servicesReceived", data.items);
          ctx.emit("totalBalanceDueUpdated", 0);
          data.pagination.currentPage = 1;
          data.pagination.totalPages = 0;
          data.pagination.totalCount = 0;
          data.pagination.pageSize = 50;
        }
      },
      { deep: true }
    );

    let data = reactive<Data>({
      payment: undefined,
      patientId: props.patientId,
      items: [],
      keyword: "",
      orderBy: "paymentsDefault",
      IsAdvancedOrderBy: true,
      isDecr: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
      panel: {},
      dict: {
        adjustmentReasonCodes: [],
        patientPayers: [],
        facilities: [],
        actualPatientPayers: [],
        actualFacilities: [],
        paymentTypes: [],
        transactionTypes: [],
        insurancePaymentTypes: [
          { id: 0, name: "Check" },
          { id: 1, name: "EFT" },
          { id: 2, name: "Virtual Card" },
        ],
      },
      postingErrors: [],
      filter: {
        startDate: null,
        endDate: null,
        facility: [],
        currentResponsibleParty: [],
      },
    });

    async function cleanFilter() {
      data.keyword = "";
      data.orderBy = "paymentsDefault";
      data.IsAdvancedOrderBy = true;
      orderList.forEach((item) => {
        item.keyword = null;
      });
      await getAll();
    }

    onMounted(async () => {
      organizationId.value = getOrganization();
      data.dict.paymentTypes = store.getters.allPaymentTypes;
      data.dict.adjustmentReasonCodes = store.getters.allAdjustmentReasonCodes;
      data.dict.transactionTypes = store.getters.allTransactionTypes;
      data.dict.patientPayers = await searchDictInsuranceCompanys({
        search: "",
      });
      data.dict.patientPayers.unshift(selfPayer);

      const panel = await getPanel();
      data.dict.facilities = panel.facilities;
      data.payment = props.payment;

      await getAll();
      data.pagination.pageSize = data.pagination.totalCount;
    });

    async function selectFilter(header) {
      if (!header.key || !header.isFilter) {
        return;
      }
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function clearSearch() {
      await nextTick();
      await search();
    }

    async function search() {
      await nextTick();
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll() {
      if (!props.patientId) {
        return;
      }
      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }
      let constList = orderList
        .filter((item) => {
          return !item.IsAdvancedOrderBy;
        })
        .map((item) => {
          return item.key;
        });
      constList.push("patient.firstName");
      let request = {
        encounterStatuses: ["BillPatient", "SubmittedToClearinghouse"],
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        PatientId: props.patientId,
        StartDate: data.filter.startDate,
        EndDate: data.filter.endDate,
        Facility: data.filter.facility,
        CurrentResponsibleParty: data.filter.currentResponsibleParty,
        GlobalSearch: data.keyword,
        IsBalanceDueNonZero: true,
      };

      const res = await searchServicesV2(request);
      data.items = res.data;
      ctx.emit("servicesReceived", data.items);
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;

      ctx.emit("totalBalanceDueUpdated", res.totalBalanceDue);

      updateFilters(res);
    }

    function updateFilters(res) {
      //This we need because vue multiselect throw EX if we have value but options list is empty
      if (res.responsibleParty.length == 0) {
        cleanHeaderFilter("encounter.responsibleParty");
      }
      if (res.facility.length == 0) {
        cleanHeaderFilter("encounter.facility.name");
      }

      //by the request result update filters to contains only actual data
      data.dict.actualPatientPayers = data.dict.patientPayers.filter((c) => {
        return res.responsibleParty.find((f) => {
          return f == c.payerId;
        });
      });

      data.dict.actualFacilities = data.dict.facilities.filter((c) => {
        return res.facility.find((f) => {
          return f == c.id;
        });
      });
    }

    function cleanHeaderFilter(headerKey) {
      (getFilterItem(headerKey) as any).keyword = null;
    }

    function getFilterItem(key) {
      const header = orderList.find((item) => {
        return item.key == key;
      });
      return header;
    }

    const totalBalanceDue = computed(() => {
      let sum = 0;
      data.items.forEach((item) => {
        sum += item.balanceDue;
      });
      return sum;
    });

    const totalPosted = computed(() => {
      let sum = 0;
      data.items.forEach((item) => {
        if (item.paymentAmount) sum += item.paymentAmount;
      });
      sum += props.payment.unappliedCredit;
      return sum;
    });

    const remainingToPost = computed(() => {
      return data.payment.totalAmount - totalPosted.value;
    });

    const newBalanceDue = computed(() => {
      return totalBalanceDue.value - totalPosted.value;
    });

    function autoPost() {
      data.items.forEach((item) => {
        item.paymentAmount = undefined;
      });
      if (remainingToPost.value <= 0) {
        return;
      }
      let postCheck = true;
      let index = 0;
      while (postCheck) {
        let service = data.items[index];
        if (remainingToPost.value > service.balanceDue) {
          service.paymentAmount = service.balanceDue;
        } else {
          service.paymentAmount = remainingToPost.value;
          postCheck = false;
        }
        if (index == data.items.length - 1) {
          postCheck = false;
        }
        index++;
      }
    }

    const postedErrorsList = computed(() => {
      let postedErrors: string[] = [];
      if (totalPosted.value < props.payment.totalAmount) {
        postedErrors.push("It is necessary to distribute the entire amount");
      }
      if (totalPosted.value > props.payment.totalAmount) {
        postedErrors.push(
          "The amount you are trying to post exceeds the total payment amount"
        );
      }
      ctx.emit("postingErrors", postedErrors);
      return postedErrors;
    });

    function cleanPost() {
      data.items.forEach((item) => {
        item.paymentAmount = undefined;
      });
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    function amountChanged() {
      ctx.emit("amountChanged");
    }

    function getReversalOriginType(item, service): number {
      if (item.reversedFromId) {
        // gets the full paymentServiceItem that was reversed
        let reversedFrom = service.paymentServiceItems.find(
          (el) => el.id == item.reversedFromId
        );
        return reversedFrom.paymentExt.type;
      }

      return 0;
    }

    function getReversalType(item, service): string {
      if (item.reversedFromId) {
        // gets the full paymentServiceItem that was reversed
        let reversedFrom = service.paymentServiceItems.find(
          (el) => el.id == item.reversedFromId
        );

        // check original type, payment or adjustment
        if (reversedFrom.paymentExt.type == 0) {
          return "Payment Reversal";
        } else if (reversedFrom.paymentExt.type == 1) {
          return "Adjustment Reversal";
        }
      }

      return "Not a reversal.";
    }

    return {
      organizationId,
      clearSearch,
      data,
      orderList,
      search,
      getSortInfo,
      selectFilter,
      cleanFilter,
      totalBalanceDue,
      totalPosted,
      newBalanceDue,
      remainingToPost,
      postedErrorsList,
      cleanPost,
      autoPost,
      amountChanged,
      pageChanged,
      pageSizeChanged,
      getReversalType,
      getReversalOriginType,
    };
  },
});
