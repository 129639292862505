
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  watch,
  computed,
} from "vue";
import { getOrganization } from "@/core/services/JwtService";
import ServiceHistoryPayment from "@/modules/payment/ServiceHistoryPayment.vue";
import ServiceHistoryPostedPayment from "@/modules/payment/ServiceHistoryPostedPayment.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Multiselect from "@vueform/multiselect";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  required,
  minLength,
  helpers,
  minValue,
  maxLength,
} from "@vuelidate/validators";
import { useRoute, useRouter } from "vue-router";
import { addPayment, getPayment } from "@/api/payment.api";
import { getPatient, searchByName } from "@/api/patient.api";
import { addPaymentServiceItems } from "@/api/services.api";
import { PaymentService } from "@/modules/encounter/encounter.model";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { getPanel } from "@/api/panel.api";
import { subNumbers, sumNumbers } from "@/utility";
import { getPaymentPlan } from "@/api/paymentPlan.api";
import { getPaymentInstallment } from "@/api/paymentInstallment.api";

export interface RevenueCode {
  code: string;
  description: string;
  chargePerUnit: number;
}

export interface Data {
  installment: any;
  postingInProgress: boolean;
  isForPatient: boolean;
  totalBalanceDue: number;
  patientId?: string;
  isDirty: boolean;
  isLoaded: boolean;
  payment?: any;
  items: PaymentService[];
  previousPayment?: any;
  createNewMode: boolean;
  canPost: boolean;
  updateOnly: boolean;
  dict: {
    paymentTypes: [];
    transactionTypes: [];
    adjustmentReasonCodes: [];
    adjustmentSigns: [];
    facilities: [];
  };
  patientOptions: { id: string }[];
}

export default defineComponent({
  name: "PatientPaymentComponent",
  components: {
    Multiselect,
    DecimalInput,
    ServiceHistoryPayment,
    ServiceHistoryPostedPayment,
  },
  props: ["paymentId", "installmentId"],
  beforeRouteLeave(to, from, next) {
    if (this.data.isDirty) {
      let text = "Are you sure you want to leave without saving changes?";

      Swal.fire({
        title: text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else if (result.isDismissed) {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  setup(props, ctx) {
    const store = useStore();
    let organizationId = ref<string | null>("");
    const serviceHistoryPayment = ref(null);
    const router = useRouter();
    const route = useRoute();
    let data = reactive<Data>({
      installment: undefined,
      postingInProgress: false,
      canPost: false,
      isForPatient: false,
      totalBalanceDue: 0,
      items: [],
      patientId: undefined,
      isDirty: false,
      isLoaded: false,
      payment: undefined,
      createNewMode: true,
      updateOnly: false,
      dict: {
        paymentTypes: [],
        transactionTypes: [],
        adjustmentReasonCodes: [],
        adjustmentSigns: [],
        facilities: [],
      },
      patientOptions: [],
    });

    watch(
      () => data.payment,
      (currentValue, oldValue) => {
        if (
          currentValue &&
          data.previousPayment &&
          JSON.stringify(currentValue) != JSON.stringify(data.previousPayment)
        ) {
          data.isDirty = true;
        }
      },
      { deep: true }
    );

    onMounted(async () => {
      organizationId.value = getOrganization();
      const panel = await getPanel();
      data.dict.facilities = panel.facilities;
      data.dict.paymentTypes = store.getters.allPaymentTypes;
      data.dict.transactionTypes = store.getters.allTransactionTypes;
      data.dict.adjustmentReasonCodes = store.getters.allAdjustmentReasonCodes;
      data.dict.adjustmentSigns = store.getters.allAdjustmentSigns;

      if (props.paymentId) {
        setCurrentPageTitle("Apply Credit");
        data.payment = await getPayment(props.paymentId);
        data.createNewMode = false;
        if (data.payment.unappliedCredit > 0) {
          data.canPost = true;
        }
      } else {
        setCurrentPageTitle("Add Patient Payment");
        data.canPost = true;
        data.createNewMode = true;
        data.payment = {
          id: "",
          type: 0,
          paymentType: 2,
          adjustmentReason: 1,
          entryDate: getUTCnow().toISOString(),
          paymentDate: getUTCnow().toISOString(),
          accountingDate: getUTCnow().toISOString(),
          unallocatedAmount: 0,
          totalAmount: null,
          unappliedCredit: null,
          adjustmentSign: 0,
        };
        const patientId = route.query.patientId;
        if (patientId) {
          const patient = await getPatient(patientId);
          data.payment.patient = patient;
          data.payment.patientId = patient.id;
          data.isForPatient = true;
        }
        const type = route.query.type;
        if (type) {
          data.payment.type = type;
        }
        if (data.payment.type == 1)
          setCurrentPageTitle("Add Patient Adjustment");
      }
      data.patientId = data.payment.patientId;
      const installmentId = route.query.installmentId;
      if (installmentId) {
        data.installment = await getPaymentInstallment(installmentId);
        data.payment.patientId = data.patientId = data.installment.paymentPlan.patientId;
        data.payment.patient = await getPatient(data.payment.patientId);
        data.isForPatient = true;
        data.payment.totalAmount = data.installment.balance;
        data.payment.paymentInstallmentId = installmentId;
      }
      data.previousPayment = JSON.parse(JSON.stringify(data.payment));
      data.isLoaded = true;
      v$.value.$validate();
    });

    function getUTCnow() {
      var date = new Date();
      const d = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
      return d;
    }
    const paymentReq = (data) =>
      helpers.withParams(
        { type: "paymentReq", value: data.payment },
        (value) => helpers.req(value) || data.payment.type != 0
      );
    const adjustmentReq = (payment) =>
      helpers.withParams(
        { type: "adjustmentReq", value: payment },
        (value) => helpers.req(value) || data.payment.type != 1
      );

    const paymentPlanBalance = (payment) =>
      helpers.withParams(
        { type: "paymentPlanBalance", value: payment },
        (value) => !data.installment || data.installment.paymentPlan.balance >= data.payment.totalAmount
      );

    const paymentRules = {
      payment: {
        patientId: {
          req: helpers.withMessage("Required", required),
        },
        type: {
          req: helpers.withMessage("Required", required),
        },
        paymentType: {
          req: helpers.withMessage("Required", required),
        },
        paymentDate: {
          paymentReq: helpers.withMessage("Required", paymentReq(data)),
        },
        adjustmentReason: {
          adjustmentReq: helpers.withMessage("Required", adjustmentReq(data)),
        },
        adjustmentSign: {
          adjustmentReq: helpers.withMessage("Required", adjustmentReq(data)),
        },
        totalAmount: {
          req: helpers.withMessage("Required", required),
          minValue: minValue(1),
          paymentPlanBalance: helpers.withMessage("Amount cannot exceed Payment Plan Balance", paymentPlanBalance(data))
        },
        reference: { maxLength: maxLength(50) },
        payerId: {
          required: helpers.withMessage("Required", adjustmentReq(data)),
        },
      },
    };

    let v$ = useVuelidate(paymentRules, data as never) as any;

    const isSaveEnabled = computed(() => {
      let formErros = v$.value.$silentErrors.length == 0;
      return (
        formErros &&
        (!postedErrorsList.value || postedErrorsList.value.length == 0)
      );
    });

    async function searchPatients(text) {
      const patients = await searchByName({ search: text });
      data.patientOptions = patients;
      return patients;
    }

    function patientSelected(patientId) {
      if (!data.createNewMode) {
        Swal.fire({
          title:
            "Are you sure you want to change the selected Patient Account?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
          allowOutsideClick: false,
          customClass: {
            actions: "my-actions",
            cancelButton: "order-1 right-gap",
            confirmButton: "order-2",
            denyButton: "order-3",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            data.payment.patientId = patientId;
            data.payment.patient = data.patientOptions.find((item) => {
              return item.id == patientId;
            });
          } else if (result.isDismissed) {
            data.patientId = data.payment.patientId;
          }
        });
      } else {
        data.payment.patientId = patientId;
        data.payment.patient = data.patientOptions.find((item) => {
          return item.id == patientId;
        });
      }
    }

    function patientRemoved() {
      data.payment.patient = null;
      data.payment.patientId = null;
    }

    async function paymentPosted() {
      data.payment = await getPayment(data.payment.id);
    }

    async function servicesReceived(items) {
      data.items = items;
    }

    const maxServDate = computed(() => {
      return getNow();
    });

    function getNow() {
      var date = new Date();
      date.setHours(0, 0, 0, 0);
      return date;
    }

    const availableCredit = computed(() => {
      if (data.createNewMode) {
        return data.payment.totalAmount;
      } else {
        return data.payment.unappliedCredit;
      }
    });

    const totalPosted = computed(() => {
      let sum = 0;
      data.items.forEach((item) => {
        if (item.paymentAmount) sum = sumNumbers(sum, item.paymentAmount);
      });
      if (data.createNewMode) {
        sum = sumNumbers(sum, data.payment.unappliedCredit);
      }
      return sum;
    });

    const remainingToPost = computed(() => {
      return subNumbers(availableCredit.value,totalPosted.value);
    });

    const newBalanceDue = computed(() => {
      return subNumbers(data.totalBalanceDue,totalPosted.value);
    });

    function autoPost() {
      data.isDirty = true;
      data.items.forEach((item) => {
        item.paymentAmount = undefined;
      });
      if (remainingToPost.value <= 0) {
        return;
      }
      data.items.forEach((item) => {
        item.paymentAmount = undefined;
      });
      let postCheck = true;
      let index = 0;
      while (postCheck) {
        let service = data.items[index];
        if (remainingToPost.value > service.balanceDue) {
          service.paymentAmount = service.balanceDue;
        } else {
          service.paymentAmount = remainingToPost.value;
          postCheck = false;
        }
        if (index == data.items.length - 1) {
          postCheck = false;
        }
        index++;
      }
    }

    const postedErrorsList = computed(() => {
      let postedErrors: string[] = [];
      // if (data.totalBalanceDue < data.payment.totalAmount) {
      //   postedErrors.push("Amount cannot exceed to the Total Balance Due");
      // }
      if (totalPosted.value == 0 && !data.createNewMode) {
        postedErrors.push("It is necessary to distribute anything");
      } else if (availableCredit.value > 0 && totalPosted.value == 0) {
        if (data.createNewMode) {
          postedErrors.push("It is necessary to distribute the amount");
        }
      } else if (
        availableCredit.value &&
        data.createNewMode &&
        totalPosted.value < data.payment.totalAmount
      ) {
        postedErrors.push("It is necessary to distribute the entire amount");
      }
      if (totalPosted.value > availableCredit.value) {
        postedErrors.push(
          "The amount you are trying to post exceeds the total payment amount"
        );
      }
      return postedErrors;
    });

    async function postPayment() {
      let validateResults = await v$.value.$validate();
      let isApplyingCredit = false;
      if (validateResults) {
        data.postingInProgress = true;
        let paymentId = "";
        if (data.createNewMode) {
          const res = (await addPayment(data.payment)) as any;
          paymentId = res.data;
        } else {
          paymentId = data.payment.id;
          isApplyingCredit = true;
        }

        if (paymentId) {
          data.isDirty = false;
          const itemsToPost = data.items
            .filter((c) => {
              return c.paymentAmount;
            })
            .map((c) => {
              return { PaymentAmount: c.paymentAmount, id: c.id };
            });
          const postRes = await addPaymentServiceItems({
            entryDate: getUTCnow().toISOString(),
            paymentId: paymentId,
            items: itemsToPost,
            isApplyingCredit,
          });
          data.payment = await getPayment(paymentId);
          data.previousPayment = JSON.parse(JSON.stringify(data.payment));
          if (data.payment.unappliedCredit > 0) {
            data.canPost = true;
          } else {
            data.canPost = false;
          }
          //TODO clean
          if (serviceHistoryPayment.value)
            (serviceHistoryPayment.value as any).cleanFilter();

          ctx.emit("paymentPosted");

          let name = "Patient payment";
          if (data.payment.type == 1) name = "Patient adjustment";
          Swal.fire("Ok!", name + " has been posted", "success");

          const patientId = route.query.patientId;
          if (patientId) {
            router.push({ path: "/patientAccounts/patient/" + patientId + "/" });
            return;
          }
          if (data.createNewMode) {
            router.push({
              path: "/Payments/PatientTransactionsList",
            });
          } else {
            router.go(-1);
          }
          data.createNewMode = false;
        }
      }
      data.postingInProgress = false;
    }

    async function cancel() {
      if (totalPosted.value > 0) {
        data.isDirty = true;
      }
      const patientId = route.query.patientId;
      if (patientId) {
        router.push({ path: "/patientAccounts/patient/" + patientId + "/" });
        return;
      }
      if (data.createNewMode) {
        router.push({
          path: "/Payments/PatientTransactionsList",
        });
      } else {
        router.go(-1);
      }
    }

    function cleanPost() {
      data.items.forEach((item) => {
        item.paymentAmount = undefined;
      });
    }

    function totalBalanceDueUpdated(totalBalanceDue) {
      data.totalBalanceDue = totalBalanceDue;
    }

    function typeSelected() {
      data.payment.unappliedCredit = null;
      data.payment.paymentType = 2;
      data.payment.adjustmentReason = 1;
      data.payment.reference = "";
      data.payment.totalAmount = null;
      data.payment.entryDate = getUTCnow().toISOString();
      data.payment.paymentDate = getUTCnow().toISOString();
      data.payment.accountingDate = getUTCnow().toISOString();
      data.items
        .filter((item) => {
          return item.paymentAmount;
        })
        .forEach((item) => {
          item.paymentAmount = undefined;
        });
    }

    function amountChanged() {
      data.isDirty = true;
    }

    return {
      organizationId,
      data,
      searchPatients,
      patientSelected,
      patientRemoved,
      paymentPosted,
      maxServDate,
      getNow,
      cancel,
      isSaveEnabled,
      servicesReceived,
      availableCredit,
      totalPosted,
      remainingToPost,
      newBalanceDue,
      autoPost,
      postedErrorsList,
      totalBalanceDueUpdated,
      serviceHistoryPayment,
      postPayment,
      cleanPost,
      typeSelected,
      amountChanged,
      v$,
    };
  },
});
